import FlowItem from "./FlowItem";
import { useEnvironment } from "~/context/EnvironmentContext";

const Flow = () => {

  // コンテキストから静的画像バケットのエンドポイントを取得
  const { staticImageBucketEndpoint } = useEnvironment();

  return (
    <section
      id="sect-flow"
      className="scroll-mt-[88px] md:scroll-mt-[112px] md:mt-[104px] md:mb-[120px] md:px-7"
    >
      <div className="relative max-w-[1280px] mx-auto py-14 bg-primary-light overflow-hidden sp:px-4 md:py-20 md:px-20 md:rounded-3xl lg:px-[120px]">
        <div className="absolute -top-10 -right-7 z-0 w-[220px] md:w-[476px] md:-top-32 md:-right-20">
          <img src={`${staticImageBucketEndpoint}/public/top/flow-bg-1.webp`} width="952" height="1209" alt="" />
        </div>
        <div className="absolute -bottom-5 -left-5 z-0 w-[217px] md:w-[327px]">
          <img src={`${staticImageBucketEndpoint}/public/top/flow-bg-2.webp`} width="654" height="747" alt="" />
        </div>
        <div className="relative z-10">
          <div className="flex items-center justify-center md:justify-start">
            <svg className="block w-5 h-5 mr-1 fill-primary md:w-6 md:h-6">
              <use xlinkHref="/symbol-defs.svg#icon-egg"></use>
            </svg>
            <p className="text-primary text-sm font-candal tracking-[0.06em] md:text-lg md:tracking-[0.06em]">
              Flow
            </p>
          </div>
          <h2 className="mb-4 text-center text-[28px] font-black leading-normal md:mb-6 md:text-left md:text-[46px] md:leading-normal">
            ご利用の流れ
          </h2>
          <p className="mb-6 px-2 text-[15px] leading-[1.8] font-medium md:mb-10 md:text-base md:leading-[1.8]">
            求人情報の検索から内定までの流れをご説明いたします。
            <br />
            企業側とのやりとりは全てキャリアアドバイザーが担当いたします。
            <br />
            求職者の皆様がやりとりするのは、基本的に担当のキャリアアドバイザーとなります。
          </p>
          <div className="grid grid-cols-1 gap-2 font-medium">
            <FlowItem stepNumber="01" stepTitle="求人情報を検索">
              『エフジョブ求人』の中から気になる求人情報を見つけましょう！
            </FlowItem>
            <FlowItem stepNumber="02" stepTitle="情報の登録">
              求人情報ページにある「この求人に問い合わせる」ボタンを押して、情報を登録してください。
            </FlowItem>
            <FlowItem stepNumber="03" stepTitle="転職の面談">
              ご登録いただいた情報を元に、キャリアアドバイザーがご希望や状況をお伺いします。その上で、お問い合わせ頂いた求人情報についてのご案内、ご希望に合致するその他の類似求人情報や適正がありそうな求人情報をご案内いたします。転職面談は、電話、オンラインミーティングツール(ZOOM、Google
              Meet)、弊社へのご来社などからお選び頂けます。
            </FlowItem>
            <FlowItem stepNumber="04" stepTitle="応募書類の作成">
              応募に使用する職務経歴書・履歴書の作成も、担当のキャリアアドバイザーがサポートいたします。
            </FlowItem>
            <FlowItem stepNumber="05" stepTitle="書類選考・面接">
              各企業の採用フローに沿って採用が進みます。面接対策として、重要な要素である「志望動機」「各社の転職理由」を考えるところからお手伝いします。ご希望に応じて面接の練習も可能です。また、年収の交渉など、面接のポイントもアドバイスいたします。
            </FlowItem>
            <FlowItem stepNumber="06" stepTitle="内定">
              入社日の調整や、新しい職場での注意点など内定した後のサポートもお任せ下さい。また、働きながら転職活動を行っている方のために円満退職のサポートも対応しております。
            </FlowItem>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Flow;
