import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import { Link } from "@remix-run/react";
import { useEnvironment } from "~/context/EnvironmentContext";

const KeyVisual = () => {

  // コンテキストから静的画像バケットのエンドポイントを取得
  const { staticImageBucketEndpoint } = useEnvironment();

  return (
    <section className="relative flex items-end justify-center h-[440px] mt-[-88px] overflow-hidden md:mt-[-112px] md:px-7">
      <div id="keyvusyakSlider" className="absolute inset-0">
        <Swiper
          modules={[Autoplay, EffectFade]}
          effect="fade"
          speed={1500}
          autoplay={{ delay: 5500 }}
          loop={true}
          spaceBetween={0}
          // onSlideChange={() => console.log("slide change")}
          // onSwiper={(swiper) => console.log(swiper)}
        >
          <SwiperSlide>
            <figure>
              <img
                src={`${staticImageBucketEndpoint}/public/top/keyvisual/keyvisual-1_sp.webp`}
                alt=""
                className="md:hidden"
              />
              <img
                src={`${staticImageBucketEndpoint}/public/top/keyvisual/keyvisual-1_pc.webp`}
                alt=""
                className="hidden md:block"
              />
            </figure>
          </SwiperSlide>
          <SwiperSlide>
            <figure>
              <img
                src={`${staticImageBucketEndpoint}/public/top/keyvisual/keyvisual-2_sp.webp`}
                alt=""
                className="md:hidden"
              />
              <img
                src={`${staticImageBucketEndpoint}/public/top/keyvisual/keyvisual-2_pc.webp`}
                alt=""
                className="hidden md:block"
              />
            </figure>
          </SwiperSlide>
          <SwiperSlide>
            <figure>
              <img
                src={`${staticImageBucketEndpoint}/public/top/keyvisual/keyvisual-3_sp.webp`}
                alt=""
                className="md:hidden"
              />
              <img
                src={`${staticImageBucketEndpoint}/public/top/keyvisual/keyvisual-3_pc.webp`}
                alt=""
                className="hidden md:block"
              />
            </figure>
          </SwiperSlide>
          <SwiperSlide>
            <figure>
              <img
                src={`${staticImageBucketEndpoint}/public/top/keyvisual/keyvisual-4_sp.webp`}
                alt=""
                className="md:hidden"
              />
              <img
                src={`${staticImageBucketEndpoint}/public/top/keyvisual/keyvisual-4_pc.webp`}
                alt=""
                className="hidden md:block"
              />
            </figure>
          </SwiperSlide>
          <SwiperSlide>
            <figure>
              <img
                src={`${staticImageBucketEndpoint}/public/top/keyvisual/keyvisual-5_sp.webp`}
                alt=""
                className="md:hidden"
              />
              <img
                src={`${staticImageBucketEndpoint}/public/top/keyvisual/keyvisual-5_pc.webp`}
                alt=""
                className="hidden md:block"
              />
            </figure>
          </SwiperSlide>
          <SwiperSlide>
            <figure>
              <img
                src={`${staticImageBucketEndpoint}/public/top/keyvisual/keyvisual-6_sp.webp`}
                alt=""
                className="md:hidden"
              />
              <img
                src={`${staticImageBucketEndpoint}/public/top/keyvisual/keyvisual-6_pc.webp`}
                alt=""
                className="hidden md:block"
              />
            </figure>
          </SwiperSlide>
          <SwiperSlide>
            <figure>
              <img
                src={`${staticImageBucketEndpoint}/public/top/keyvisual/keyvisual-7_sp.webp`}
                alt=""
                className="md:hidden"
              />
              <img
                src={`${staticImageBucketEndpoint}/public/top/keyvisual/keyvisual-7_pc.webp`}
                alt=""
                className="hidden md:block"
              />
            </figure>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="relative text-white z-10 pb-12 md:pb-[107px]">
        <p className="font-black text-white text-center text-xl [&_strong]:text-[32px] leading-[1.2] md:text-[40px] md:[&_strong]:text-[56px] md:leading-[1.12]">
          <strong>理想の転職</strong>を<br />
          飲食業界のプロと一緒に<strong>実現</strong>する
        </p>
        <div className="mt-6 text-center">
          <Link
            to="/#sect-about"
            className={`group inline-block w-[240px] p-2.5  rounded-full border-2 bg-white shadow-[4px_4px_0_0_rgba(51,51,51,0.25)] border-primary text-primary hover:bg-primary-light md:w-[256px] md:p-3`}
          >
            <span className="flex items-center justify-center">
              <span className="text-[15px] font-bold leading-[1.8] md:text-base md:leading-[1.8]">
                エフジョブ求人とは
              </span>
              <span className="ml-2.5">
                <svg className="block aspect-square w-[14px] fill-primary md:w-[18px]">
                  <use xlinkHref="/symbol-defs.svg#icon-arrow-right"></use>
                </svg>
              </span>
            </span>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default KeyVisual;
