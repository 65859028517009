import SearchItem from "./SearchItem";
import TopHeading from "./TopHeading";
import SimpleBarWrap from "./SimpleBarWrap";
import type { OccupationType, Masters } from "~/types";
import { useEnvironment } from "~/context/EnvironmentContext";

const SearchByPosition = ({ masters }: { masters: Masters }) => {
  const occupationTypes = masters.occupationTypes;

  // クエリパラメーターを生成
  const generateQueryParams = (occupationTypeIds: string) => {
    const params = new URLSearchParams();
    params.append("occupation_type_ids", occupationTypeIds);
    return params.toString();
  };

  // コンテキストから静的画像バケットのエンドポイントを取得
  const { staticImageBucketEndpoint } = useEnvironment();

  return (
    <section className="my-20 md:my-[104px] md:px-7">
      <div className="md:max-w-[1040px] md:mx-auto">
        <div className="mb-6 sp:px-4 md:mb-8">
          <TopHeading
            text="職種から探す"
            icon="shirt"
            iconBg="primary"
          ></TopHeading>
        </div>
        <SimpleBarWrap>
          <div className="grid gap-4 sp:grid-cols-[repeat(auto-fill,minmax(146px,1fr))] sp:w-[calc(((146px_+16px)_*_26)_+_16px)] sp:px-4 sp:pb-8 sp:[&_a]:flex-shrink-0 sp:[&_a]:w-[146px] md:grid-cols-[repeat(auto-fill,minmax(195px,1fr))]">
            {occupationTypes.map((occupationType: OccupationType) => (
              <SearchItem
                key={occupationType.id}
                linkUrl={`/list?${generateQueryParams(occupationType.id)}`}
                imageUrl={`${staticImageBucketEndpoint}/public/top/position/position-${occupationType.key}.webp`}
                text={occupationType.name}
              ></SearchItem>
            ))}
          </div>
        </SimpleBarWrap>
      </div>
    </section>
  );
};

export default SearchByPosition;
